<template>
  <div id="home">
    <van-pull-refresh v-model="loading" @refresh="refreshTours">
      <div class="home">
        <van-row>
          <van-col span="20">
            <van-col span="5">
              <div class="home-header">导游</div>
            </van-col>
            <van-col span="19">
              <svg-icon
                :data_iconName="'cloudy-day'"
                style="font-size: 0.8rem; margin-top: -0.12rem"
                @click.native="$router.push('/weather')"
              />
            </van-col>
          </van-col>
          <van-col span="4" style="text-align: right" @click="sign_out">
            <svg-icon
              :data_iconName="'signout'"
              :className="'signout_icon'"
              style="line-height: 1.1rem; margin-top: -0.1rem"
            />
          </van-col>
        </van-row>
        <div class="home-body">
          <card v-if="user.user">
            <template #content>
              <van-row align="center">
                <van-col
                  span="5"
                  style="min-height: 60px; padding-bottom: 10px"
                >
                  <van-row style="height: 60px">
                    <van-image
                      style="margin-top: 10px"
                      round
                      :src="user.user ? user.user.avatar : ''"
                      fit="cover"
                      width="60"
                      height="60"
                    />
                  </van-row>
                  <van-row
                    style="
                      margin-top: -20px;
                      width: 60px;
                      text-align: center;
                      height: 25px;
                    "
                  >
                    <van-tag
                      round
                      type="primary"
                      size="medium"
                      color="#EDF3FD"
                      text-color="#699FF2"
                    >
                      {{
                        user.guide.role_name == "讲解员"
                          ? "讲解员"
                          : user.guide.level == ""
                          ? "暂无"
                          : user.guide.level + "导游"
                      }}
                    </van-tag>
                  </van-row>
                </van-col>
                <van-col span="16" style="font-size: 12px; display: block">
                  <van-row
                    style="
                      font-size: 18px;
                      font-weight: 600;
                      margin-top: 10px;
                      height: 25px;
                      line-height: 25px;
                      display: block;
                    "
                    type="flex"
                  >
                    {{ user.guide ? user.guide.name : "" }}
                    <van-tag
                      type="primary"
                      color="#4BB74E13"
                      v-for="(languageItem, idx) in fuser.language"
                      :key="idx"
                      :text-color="
                        languageItem == '普通话' ? '#4BB74E' : '#94ae40'
                      "
                      round
                      size="small"
                      style="margin-left: 5px"
                    >
                      {{ languageItem }}
                    </van-tag>
                    <van-rate
                      allow-half
                      readonly
                      v-model="user.guide.guide_rate_star"
                      color="rgb(255, 170, 0)"
                      size="15"
                      gutter="1px"
                      void-color="#ffffff"
                      style="padding-top: 0.1rem"
                    />
                    <van-col span="24" class="info-txt">
                      电话：{{ user.user.mobile }}
                    </van-col>
                    <van-col span="24" class="info-txt">
                      {{
                        user.agency === null || user.guide.status == 4
                          ? "无"
                          : user.agency.name
                      }}
                    </van-col>
                  </van-row>
                </van-col>
                <van-col
                  span="3"
                  @click="toWodeEdit()"
                  style="margin-top: 0.4rem"
                >
                  <svg-icon
                    :data_iconName="'edit-deep'"
                    :className="'setting_icon'"
                  />
                </van-col>
              </van-row>
            </template>
          </card>
          <div class="home-body-footer">
            <van-row>
              <van-col
                span="12"
                v-for="(item, index) in menus"
                :key="index"
                class="home-body-footer-item"
                @click="onMenuClick(item)"
              >
                <van-row style="line-height: 5px; text-align: right">
                  <van-badge
                    dot
                    style="margin-right: 0.4rem"
                    v-if="item.badge"
                  />
                  <van-badge
                    dot
                    color="transparent"
                    style="margin-right: 0.4rem"
                    v-if="!item.badge"
                  />
                </van-row>
                <svg-icon :data_iconName="item.icon" :className="'item_icon'" />
                <div class="item-text">{{ item.text }}</div>
              </van-col>
            </van-row>
          </div>
          <tourCard :tour="tour" v-for="(tour, idx) in onGoingTours" :key="idx">
          </tourCard>
          <span style="display: none">{{ user }}</span>
        </div>
      </div>
    </van-pull-refresh>
    <Affiliated></Affiliated>
  </div>
</template>

<script>
import tourCard from "@/views/components/TourCard";
import card from "@/views/components/Card";
import Affiliated from "@/views/components/Affiliated";
import { mapGetters } from "vuex";
import api from "@/services/apis.js";
import { Toast, Dialog } from "vant";
export default {
  name: "Home",
  components: {
    tourCard,
    card,
    Affiliated,
  },
  data() {
    return {
      showCompanyInfoConfirm: false,
      loading: false,
      notifications: [],
      emergency_unapproved_count: 0,
      notification_unread_count: 0,
      events: [],
      menus: [
        {
          text: "扫一扫",
          icon: "scan",
          path: "/scan",
        },
        {
          text: "个人中心",
          icon: "wode",
          path: "/wode",
          badge: false,
        },
        {
          text: "出团信息",
          icon: "tour",
          path: "/tour",
          badge: false,
        },
        {
          text: "消息通知",
          icon: "xiaoxi",
          path: "/xiaoxi",
          badge: false,
        },
        {
          text: "事件",
          icon: "event",
          path: "/events",
          badge: false,
        },

        // {
        //   text: "疫情",
        //   icon: "event",
        //   path: "/yiqing",
        //   badge: false,
        // },
        {
          text: "培训管理",
          icon: "event",
          path: "/trainManage",
          badge: false,
        },
      ],
    };
  },
  methods: {
    onMenuClick(item) {
      if (this.user.guide.status == 2 && item.icon != "wode") {
        Toast("您的账号还在审核，尚无操作权限");
      } else if (this.user.guide.status == 3 && item.icon != "wode") {
        Toast("您的账号审核被拒绝，尚无操作权限");
      } else {
        this.$router.push({ path: item.path });
      }
    },
    onTourClick(id) {
      if (this.user.guide.status == 2) {
        Toast("您的账号还在审核，尚无操作权限");
      } else if (this.user.guide.status == 3) {
        Toast("您的账号审核被拒绝，尚无操作权限");
      } else {
        this.$router.push(`/tour/info/${id}`);
      }
    },
    toWodeEdit() {
      this.$router.push("/wode/edit");
    },
    doCompanyInfoConfirm(agreeFlag) {
      const _self = this;
      const data = {
        id: this.user.guide.id,
        status: agreeFlag,
      };
      api.guide_confirm_relate(data).then((res) => {
        if (res.data.status === 200) {
          _self.showCompanyInfoConfirm = false;
        }
      });
      this.$store.dispatch("wode/getPersonInfo");
    },
    sign_out() {
      const _self = this;
      Dialog.confirm({
        title: "操作提醒",
        message: "退出当前账号？",
      }).then(() => {
        _self.$store.dispatch("wode/resetState");
        _self.$store.dispatch("travel_list/resetState");
        _self.$router.push("/login");
      });
    },
    refreshTours() {
      const _self = this;
      if (this.user.guide.status == 1) {
        this.$store.dispatch("travel_list/getAllTravelLists", 1).then((res) => {
          if (res.status === 200) {
            _self.loading = false;
          }
        });
      } else {
        _self.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("travel_list", {
      onGoingTours: "onGoingTours",
    }),
    ...mapGetters("wode", {
      user: "getPersonInfo",
      fuser: "getFmtPersonInfo",
    }),
  },
  created() {
    const _self = this;
    this.$store.dispatch("wode/getPersonInfo").then((personInfo) => {
      if (personInfo.guide.status == 3) {
        this.$dialog
          .confirm({
            title: "注册申请被拒绝",
            message: "原因:" + personInfo.guide.reject_reason,
            confirmButtonText: "修改提交",
            cancelButtonText: "取消",
          })
          .then(() => {
            _self.$router.push("/wode/edit");
          })
          .catch(() => {});
      }
      if (personInfo.guide.status == 1) {
        this.$store.dispatch("travel_list/getAllTravelLists", 1);
        this.$store.dispatch("xiaoxi/getAllNotificationsByType", {
          type: 3,
          page: 1,
        });
      }
    });
    api.count_info().then((res) => {
      if (res.data.status === 200) {
        _self.menus[3].badge = res.data.data.notification_unread_count > 0;
        _self.menus[4].badge = res.data.data.emergency_unapproved_count > 0;
        _self.menus[5].badge = res.data.data.training_unread_count > 0;
      }
    });
  },
};
</script>

<style>
#home {
  overflow-y: scroll;
  height: 100vh;
}
.home {
  padding: 0.3rem;
}
.home-header {
  color: #fff;
  font-weight: bold;
  font-size: 0.5rem;
  height: 10vh;
  width: 100%;
  text-shadow: 0 0 2px #000;
}
.home-body {
  width: 100%;
  padding-bottom: 2rem;
  overflow-y: scroll;
  overflow: hidden;
}
.home-body-header {
  height: 10vh;
  position: relative;
}
.home-body-header .avatar {
  height: 0.7rem;
  width: 0.7rem;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
.home-body-header .avatar img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.home-body-header .username {
  position: absolute;
  top: 0;
  left: 1rem;
  color: #fff;
  font-size: 0.3rem;
  font-weight: bold;
}
.home-body-header .mobile {
  position: absolute;
  top: 0.35rem;
  left: 1rem;
  color: #fff;
  font-size: 0.25rem;
  /* font-weight: bold; */
}
.home-body-middle {
  background: #fff;
  height: 15vh;
  box-shadow: 0 0 14px 0 rgba(29, 62, 203, 0.64);
  border-radius: 28px;
  border-radius: 28px;
  padding: 0.5rem;
}
.home-body-middle .number {
  color: #333;
  font-size: 24px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
}
.home-body-middle .text {
  color: #666;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.home-body-footer {
  background: #fff;
  margin-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 0 14px 0 rgba(29, 62, 203, 0.64);
  border-radius: 20px;
  border-radius: 20px;
  /* padding: .4rem; */
}
.home-body-footer-item {
  text-align: center;
  padding: 0.3rem;
}
.item-text {
  font-size: 16px;
  color: #666666;
}
.item_icon {
  font-size: 1.4rem;
}
.sitting {
  position: absolute;
  top: -0.2rem;
  right: 0px;
}
.signout {
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 5px;
  color: #000;
}
.signout_icon,
.setting_icon {
  font-size: 0.8rem;
}
.info-txt {
  margin-top: 3px;
  color: grey;
  font-size: 12px;
  line-height: 12px;
  height: 12px;
}
</style>
